.icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
}

.icon-facebook {
    background-image: data-uri('../ext/IcoMoon/facebook2.svg');
}

.icon-share {
    background-image: data-uri('../ext/IcoMoon/share.svg');
}

.icon-youtube {
    background-image: data-uri('../ext/IcoMoon/youtube.svg');
}
