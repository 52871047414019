@import (inline) '../node_modules/@splidejs/splide/dist/css/splide.min.css';

@import 'lib/icons';

@color-gray: #b6b6b6;
@color-orange: #ffb100;
@color-red: #cd1b22;

html {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%), url(../pic/background-pattern.png);
    background-repeat: no-repeat, repeat;
    background-size: 100% 1100px, auto;
    height: 100%;
}

body {
    color: black;
    display: flex;
    flex-direction: column;
    font: 15px/1.267 Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 auto;
    min-height: 100%;
    width: 960px;
}

p, form {
    margin: 1.267em 0;
}

h1 {
    background: url(../pic/logo.png) no-repeat;
    height: 167px;
    margin: 0;
}

nav {
    background: white;
    padding-top: 13px;
}

nav ul {
    background: black;
    color: white;
    cursor: default;
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    list-style: none;
    margin: 0;
    padding: 0 10px;
    text-transform: uppercase;
    text-align: justify;
}

nav .lowercase {
    text-transform: lowercase;
}

nav ul:after {
    content: '';
    display: inline-block;
    width: 100%;
}

nav li {
    display: inline-block;
}

nav a {
    color: white;
    display: inline-block;
    line-height: 40px;
    padding: 0 30px;
    text-decoration: none;
}

nav a.highlighted {
    color: @color-orange;
}

main {
    background: white;
    display: block;
    flex: auto;
    overflow: auto;
}

#content-wrapper {
    margin: 50px 0 75px 0;
}

h2 {
    font: 25px/1.52 Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: lighter;
    margin-bottom: 4px;
}

h2 + p {
    margin-top: 14px;
}

h3 {
    font: 20px/1.9 Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: lighter;
    margin: 27px 0 0;
}

h3 + p {
    margin-top: 13px;
}

main a {
    color: @color-red;
    text-decoration: none;
}

main a:hover {
    text-decoration: underline;
}

main a img {
    border: none;
}

#slideshow {
    margin: 32px 70px;
}

#slideshow a {
    height: 460px;
    overflow: hidden;
    position: relative;
    width: 820px;
}

#slideshow img {
    min-height: 200px;
    width: 100%;
}

#slideshow a, #slideshow img {
    display: block;
}

#slideshow .text {
    bottom: 0;
    left: 0;
    margin-bottom: 54px;
    position: absolute;
}

#slideshow .location {
    font-size: 15px;
    margin-top: -11px;
}

#slideshow h2 {
    color: @color-orange;
    font-size: 36px;
    line-height: 49px;
    margin-bottom: 15px;
}

#slideshow p {
    margin: 16px 0;
}

#slideshow .teaser {
    border-bottom: 3px solid @color-red;
    color: white;
    font-size: 20px;
}

#slideshow h2, #slideshow .teaser {
    background: black;
    clear: left;
    float: left;
    max-width: 600px;
    padding: 0 15px 0 17px;
}

.menuPage {
    margin: 0 0 42px 70px;
    overflow: visible;
    position: relative;
}

.menuPage-label {
    color: white;
    left: -70px;
    line-height: 26px;
    padding: 0 11px 0 70px;
    position: absolute;
    text-transform: uppercase;
    top: -23px;
}

.menuPage-label-red {
    background: @color-red;
}

.menuPage-label-yellow {
    background: @color-orange;
}

.menuPage-label-grey {
    background: @color-gray;
}

.menuPage-item {
    border-bottom: 3px solid @color-red;
    color: black;
    float: left;
    margin: 0 29px 11px 0;
    padding-bottom: 2px;
    position: relative;
}

.menuPage-item-yellow, .menuPage-item-grey {
    color: #7a7a7a;
}

.menuPage-item-yellow {
    border-bottom-color: @color-orange;
}

.menuPage-item-grey {
    border-bottom-color: @color-gray;
}

.menuPage-item.selectable:hover {
    text-decoration: none;
}

.menuPage-item-yellow.selectable:hover, .menuPage-item-grey.selectable:hover {
    color: black;
}

.menuPage-item.selectable:hover h2 {
    color: @color-red;
}

.menuPage-item.selectable:hover::before {
    border: 1px solid #d7d7d7;
    bottom: -19px;
    content: '';
    display: block;
    left: -16px;
    position: absolute;
    right: -16px;
    top: 10px;
}

.menuPage-item-small {
    width: 254px;
}

.menuPage-item-medium {
    width: 537px;
}

.menuPage-item-large {
    width: 820px;
}

.menuPage-image {
    float: right;
    margin: 32px 0 19px 29px;
    max-height: 400px;
    max-width: 254px;
}

.menuPage-item .locations {
    color: #707070;
    font-size: 13px;
    line-height: 19px;
    margin: -18px 0 16px;
    text-transform: uppercase;
}

.menuPage-item-yellow h2, .menuPage-item-grey h2 {
    font-size: 20px;
}

.teaser-image {
    margin-top: 32px;
    position: relative;
}

.teaser-image img {
    display: block;
    min-height: 150px;
    width: 100%;
}

.teaser-image h2, .menuPage-item:hover .teaser-image h2 {
    background: black;
    bottom: 0;
    color: white;
    left: 0;
    margin-bottom: 15px;
    max-width: 400px;
    padding: 0 15px 0 17px;
    position: absolute;
}

article {
    float: left;
    margin: 0 0 0 70px;
    width: 537px;
}

aside {
    float: right;
    margin-right: 70px;
    width: 254px;
}

aside, aside img {
    max-width: 254px;
}

aside > h3:first-child {
    position: relative;
    top: -7px;
}

aside > figure {
    position: relative;
    top: 33px;
}

figure {
    margin: 0 0 74px;
}

figcaption {
    color: #969696;
    font: 14px/1.357 Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-style: italic;
    margin: 4px 0;
}

aside ul {
    list-style: none;
    margin: -7px 0 0;
    padding: 0;
}

aside li {
    margin-bottom: 27px;
}

aside h4 {
    color: @color-red;
    margin: -7px 0 0;
    text-transform: uppercase;
}

aside ul h3 {
    margin: 0;
}

aside ul p {
    margin: 0;
}

aside .action-button {
    margin: 5px 0;
}

.pastOccasionsHeader:not(:first-child) {
    padding-top: 27px;
}

.pastOccasion, .pastOccasion h4 {
    color: #7a7a7a;
}

iframe {
    border: 1px solid #ccc;
    box-sizing: border-box;
}

footer {
    align-items: center;
    background: #f90;
    color: white;
    display: flex;
    font-size: 13px;
    height: 40px;
    padding: 0 10px;
}

footer span {
    flex: none;
    margin: 0 auto 0 10px;
}

footer a {
    color: white;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}

footer .icon {
    display: inline-block;
    height: 30px;
    width: 36px;
}

.social {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.hide-text {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.clear {
    clear: both;
}

.clearfix:before, .clearfix:after {
    content: '';
    display: table;
}

.clearfix:after {
    clear: both;
}

.shop {
    padding: 0 70px;
}

.shop-item {
    margin-bottom: 20px;
    overflow: auto;
}

.shop-item-image {
    margin-top: 31px;
    max-width: 254px;
}

.shop-item-aside {
    float: left;
    width: 254px;
}

.shop-item-content {
    margin-left: 284px;
}

.button, .action-button {
    background-color: #f90;
    border: 1px solid rgba(0, 0, 0, 0.05);
    color: white;
    cursor: pointer;
    display: inline-block;
    font: inherit;
    border-radius: 3px;
    padding: 4px 15px 3px 15px;
}

.button:hover, .action-button:hover {
    text-decoration: none;
}

.action-button {
    &:extend(.icon);
    &:extend(.icon-share);

    background-position: 15px center;
    padding-left: 36px;
}

.text-input {
    font: inherit;
    padding: 2px 5px;
}

.text-input:invalid {
    outline-color: @color-red;
}

.newsletterBox-input {
    margin-bottom: 10px;
    width: 100%;
}

.splide__arrow--prev {
    left: -3em;
 }

.splide__arrow--next {
    right: -3em;
}

.splide__pagination {
    bottom: -1.5em;
}

.splide__pagination__page.is-active {
    background: currentColor;
}
